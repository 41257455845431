import { Title } from '@solidjs/meta';
import { ActivityIndicator } from '@troon/ui';
import { StyleCard } from '../_style-card';

export default function ActivityIndicatorPage() {
	return (
		<>
			<Title>ActivityIndicator | Components | Style guide | Troon</Title>
			<h1 class="text-3xl font-semibold">Avatar</h1>
			<div class="mb-8 grid grid-cols-12 gap-4">
				<StyleCard title="ActivityIndicator">
					<ActivityIndicator>Loading…</ActivityIndicator>
					<div class="flex flex-row flex-wrap justify-center gap-2">
						<ActivityIndicator />
						<span class="text-brand">
							<ActivityIndicator />
						</span>
						<span class="text-red-500">
							<ActivityIndicator />
						</span>
						<span class="text-green-500">
							<ActivityIndicator />
						</span>
						<span class="text-blue-500">
							<ActivityIndicator />
						</span>
					</div>
				</StyleCard>
			</div>
		</>
	);
}
